import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_4fb47b33 from 'nuxt_plugin_plugin_4fb47b33' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_gtm_55809537 from 'nuxt_plugin_gtm_55809537' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_nuxtplugin49255b30_2837e516 from 'nuxt_plugin_nuxtplugin49255b30_2837e516' // Source: ./nuxt.plugin.49255b30.js (mode: 'server')
import nuxt_plugin_vuewebcam_2ffec130 from 'nuxt_plugin_vuewebcam_2ffec130' // Source: ./vue-web-cam.js (mode: 'client')
import nuxt_plugin_axios_58c5ba21 from 'nuxt_plugin_axios_58c5ba21' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_apollomodule_94fe5626 from 'nuxt_plugin_apollomodule_94fe5626' // Source: ./apollo-module.js (mode: 'all')
import nuxt_plugin_vuescrollto_50c25670 from 'nuxt_plugin_vuescrollto_50c25670' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_74f0f7c9 from 'nuxt_plugin_cookieuniversalnuxt_74f0f7c9' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_32ba1a3a from 'nuxt_plugin_pluginutils_32ba1a3a' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_726157e4 from 'nuxt_plugin_pluginseo_726157e4' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_229d0105 from 'nuxt_plugin_pluginrouting_229d0105' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_62abdeec from 'nuxt_plugin_pluginmain_62abdeec' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_nuxtplugin23176763_2f916822 from 'nuxt_plugin_nuxtplugin23176763_2f916822' // Source: ./nuxt.plugin.23176763.js (mode: 'all')
import nuxt_plugin_pluginserver_7d5e6f22 from 'nuxt_plugin_pluginserver_7d5e6f22' // Source: ./plugin.server.ts (mode: 'server')
import nuxt_plugin_pluginclient_5b9756e7 from 'nuxt_plugin_pluginclient_5b9756e7' // Source: ./plugin.client.ts (mode: 'client')
import nuxt_plugin_distpluginb7796d52_7d15ec86 from 'nuxt_plugin_distpluginb7796d52_7d15ec86' // Source: ./dist.plugin.b7796d52.mjs (mode: 'all')
import nuxt_plugin_image_43ccb71c from 'nuxt_plugin_image_43ccb71c' // Source: ./image.js (mode: 'all')
import nuxt_plugin_coreplugin6870ce74_50b666a7 from 'nuxt_plugin_coreplugin6870ce74_50b666a7' // Source: ./core.plugin.6870ce74.ts (mode: 'all')
import nuxt_plugin_pluginse2etesting06e4ec54_08485f44 from 'nuxt_plugin_pluginse2etesting06e4ec54_08485f44' // Source: ./plugins.e2e-testing.06e4ec54.js (mode: 'all')
import nuxt_plugin_pluginsssra3423486_6581d004 from 'nuxt_plugin_pluginsssra3423486_6581d004' // Source: ./plugins.ssr.a3423486.js (mode: 'all')
import nuxt_plugin_pluginscontext2e862100_4c398dda from 'nuxt_plugin_pluginscontext2e862100_4c398dda' // Source: ./plugins.context.2e862100.js (mode: 'all')
import nuxt_plugin_deviceplugin_a454bea8 from 'nuxt_plugin_deviceplugin_a454bea8' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_7fdfaa06 from 'nuxt_plugin_workbox_7fdfaa06' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_33412510 from 'nuxt_plugin_metaplugin_33412510' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_37e8ca84 from 'nuxt_plugin_iconplugin_37e8ca84' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_initMagentoStoreConfig_4ca9610a from 'nuxt_plugin_initMagentoStoreConfig_4ca9610a' // Source: ../plugins/initMagentoStoreConfig.ts (mode: 'client')
import nuxt_plugin_axiosplugin_7bad505b from 'nuxt_plugin_axiosplugin_7bad505b' // Source: ../plugins/axios_plugin.ts (mode: 'all')
import nuxt_plugin_filters_2dd71012 from 'nuxt_plugin_filters_2dd71012' // Source: ../plugins/filters.js (mode: 'all')
import nuxt_plugin_scrollToTopclient_6f28b416 from 'nuxt_plugin_scrollToTopclient_6f28b416' // Source: ../plugins/scrollToTop.client.js (mode: 'client')
import nuxt_plugin_infiniteloading_68d2fb1c from 'nuxt_plugin_infiniteloading_68d2fb1c' // Source: ../plugins/infiniteloading (mode: 'client')
import nuxt_plugin_apolloclient_59c77e44 from 'nuxt_plugin_apolloclient_59c77e44' // Source: ../plugins/apollo-client.js (mode: 'all')
import nuxt_plugin_mirage_6636a3b8 from 'nuxt_plugin_mirage_6636a3b8' // Source: ../plugins/mirage.js (mode: 'all')
import nuxt_plugin_tippy_7d0eb109 from 'nuxt_plugin_tippy_7d0eb109' // Source: ../plugins/tippy.js (mode: 'client')
import nuxt_plugin_vclickoutside_12eb1bf8 from 'nuxt_plugin_vclickoutside_12eb1bf8' // Source: ../plugins/v-click-outside.js (mode: 'client')
import nuxt_plugin_tokenexpired_ca1e20ca from 'nuxt_plugin_tokenexpired_ca1e20ca' // Source: ../plugins/token-expired (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_fcPlugin_5a846bc6 from 'nuxt_plugin_fcPlugin_5a846bc6' // Source: ../plugins/fcPlugin (mode: 'all')
import nuxt_plugin_dompurify_9e093c4a from 'nuxt_plugin_dompurify_9e093c4a' // Source: ../plugins/dompurify (mode: 'all')
import nuxt_plugin_vuetouch_737c6982 from 'nuxt_plugin_vuetouch_737c6982' // Source: ../plugins/vue-touch (mode: 'client')
import nuxt_plugin_vto_30304c48 from 'nuxt_plugin_vto_30304c48' // Source: ../plugins/vto.js (mode: 'all')
import nuxt_plugin_algolia_20bb0a32 from 'nuxt_plugin_algolia_20bb0a32' // Source: ../plugins/algolia.ts (mode: 'all')
import nuxt_plugin_createerrormodal_273444ec from 'nuxt_plugin_createerrormodal_273444ec' // Source: ../plugins/create-error-modal.js (mode: 'client')
import nuxt_plugin_vuequeryplugin_3a70e4bc from 'nuxt_plugin_vuequeryplugin_3a70e4bc' // Source: ../plugins/vue-query-plugin.js (mode: 'all')
import nuxt_plugin_meta_9a50f1f6 from 'nuxt_plugin_meta_9a50f1f6' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Zenni Optical For Molina Healthcare","meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"\u003C!-- ALL-CONTRIBUTORS-BADGE:START - Do not remove or modify this section --\u003E"},{"name":"generator","content":"Vue Storefront 2"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Fweb.glasseson.com\u002Fcss\u002Ffonts.css"},{"rel":"preconnect","href":"https:\u002F\u002Fres-4.cloudinary.com","crossorigin":true},{"rel":"dns-prefetch","href":"https:\u002F\u002Fres-4.cloudinary.com"}],"script":[{"vmid":"luna-vto","src":"https:\u002F\u002Fbsdk.api.ditto.com\u002Fdefault\u002F5.5.0\u002Fen-us\u002Fapi.js"},{"vmid":"luna","src":"https:\u002F\u002Fweb.cdn.glasseson.com\u002Fglasseson-2.23.1.js"},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-5GZL629')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"style":[],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-5GZL629&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_4fb47b33 === 'function') {
    await nuxt_plugin_plugin_4fb47b33(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_55809537 === 'function') {
    await nuxt_plugin_gtm_55809537(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin49255b30_2837e516 === 'function') {
    await nuxt_plugin_nuxtplugin49255b30_2837e516(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuewebcam_2ffec130 === 'function') {
    await nuxt_plugin_vuewebcam_2ffec130(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_58c5ba21 === 'function') {
    await nuxt_plugin_axios_58c5ba21(app.context, inject)
  }

  if (typeof nuxt_plugin_apollomodule_94fe5626 === 'function') {
    await nuxt_plugin_apollomodule_94fe5626(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_50c25670 === 'function') {
    await nuxt_plugin_vuescrollto_50c25670(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_74f0f7c9 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_74f0f7c9(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_32ba1a3a === 'function') {
    await nuxt_plugin_pluginutils_32ba1a3a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_726157e4 === 'function') {
    await nuxt_plugin_pluginseo_726157e4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_229d0105 === 'function') {
    await nuxt_plugin_pluginrouting_229d0105(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_62abdeec === 'function') {
    await nuxt_plugin_pluginmain_62abdeec(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtplugin23176763_2f916822 === 'function') {
    await nuxt_plugin_nuxtplugin23176763_2f916822(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_7d5e6f22 === 'function') {
    await nuxt_plugin_pluginserver_7d5e6f22(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_5b9756e7 === 'function') {
    await nuxt_plugin_pluginclient_5b9756e7(app.context, inject)
  }

  if (typeof nuxt_plugin_distpluginb7796d52_7d15ec86 === 'function') {
    await nuxt_plugin_distpluginb7796d52_7d15ec86(app.context, inject)
  }

  if (typeof nuxt_plugin_image_43ccb71c === 'function') {
    await nuxt_plugin_image_43ccb71c(app.context, inject)
  }

  if (typeof nuxt_plugin_coreplugin6870ce74_50b666a7 === 'function') {
    await nuxt_plugin_coreplugin6870ce74_50b666a7(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etesting06e4ec54_08485f44 === 'function') {
    await nuxt_plugin_pluginse2etesting06e4ec54_08485f44(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsssra3423486_6581d004 === 'function') {
    await nuxt_plugin_pluginsssra3423486_6581d004(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginscontext2e862100_4c398dda === 'function') {
    await nuxt_plugin_pluginscontext2e862100_4c398dda(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_a454bea8 === 'function') {
    await nuxt_plugin_deviceplugin_a454bea8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_7fdfaa06 === 'function') {
    await nuxt_plugin_workbox_7fdfaa06(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_33412510 === 'function') {
    await nuxt_plugin_metaplugin_33412510(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_37e8ca84 === 'function') {
    await nuxt_plugin_iconplugin_37e8ca84(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_initMagentoStoreConfig_4ca9610a === 'function') {
    await nuxt_plugin_initMagentoStoreConfig_4ca9610a(app.context, inject)
  }

  if (typeof nuxt_plugin_axiosplugin_7bad505b === 'function') {
    await nuxt_plugin_axiosplugin_7bad505b(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2dd71012 === 'function') {
    await nuxt_plugin_filters_2dd71012(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scrollToTopclient_6f28b416 === 'function') {
    await nuxt_plugin_scrollToTopclient_6f28b416(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_infiniteloading_68d2fb1c === 'function') {
    await nuxt_plugin_infiniteloading_68d2fb1c(app.context, inject)
  }

  if (typeof nuxt_plugin_apolloclient_59c77e44 === 'function') {
    await nuxt_plugin_apolloclient_59c77e44(app.context, inject)
  }

  if (typeof nuxt_plugin_mirage_6636a3b8 === 'function') {
    await nuxt_plugin_mirage_6636a3b8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_tippy_7d0eb109 === 'function') {
    await nuxt_plugin_tippy_7d0eb109(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vclickoutside_12eb1bf8 === 'function') {
    await nuxt_plugin_vclickoutside_12eb1bf8(app.context, inject)
  }

  if (typeof nuxt_plugin_tokenexpired_ca1e20ca === 'function') {
    await nuxt_plugin_tokenexpired_ca1e20ca(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_fcPlugin_5a846bc6 === 'function') {
    await nuxt_plugin_fcPlugin_5a846bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_dompurify_9e093c4a === 'function') {
    await nuxt_plugin_dompurify_9e093c4a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetouch_737c6982 === 'function') {
    await nuxt_plugin_vuetouch_737c6982(app.context, inject)
  }

  if (typeof nuxt_plugin_vto_30304c48 === 'function') {
    await nuxt_plugin_vto_30304c48(app.context, inject)
  }

  if (typeof nuxt_plugin_algolia_20bb0a32 === 'function') {
    await nuxt_plugin_algolia_20bb0a32(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_createerrormodal_273444ec === 'function') {
    await nuxt_plugin_createerrormodal_273444ec(app.context, inject)
  }

  if (typeof nuxt_plugin_vuequeryplugin_3a70e4bc === 'function') {
    await nuxt_plugin_vuequeryplugin_3a70e4bc(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_9a50f1f6 === 'function') {
    await nuxt_plugin_meta_9a50f1f6(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
